// Easy Web JavaScript

(function ($) {
  "use strict"; // Start of use strict

  /* Date Picker Init*/
  $(function () {
    $('#datepicker1').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        time: "glyphicon glyphicon-time",
        date: "fa fa-calendar",
        up: "fa fa-arrow-up",
        down: "fa fa-arrow-down",
        previous: "glyphicon glyphicon-menu-left",
        next: "glyphicon glyphicon-menu-right"
      }
    });
    $('#datetpicker2').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        time: "glyphicon glyphicon-time",
        date: "fa fa-calendar",
        up: "fa fa-arrow-up",
        down: "fa fa-arrow-down",
        previous: "glyphicon glyphicon-menu-left",
        next: "glyphicon glyphicon-menu-right"
      }
    });
  });
  /* Date Range Picker Init*/
  $(function () {
    $('#datetimepicker1').daterangepicker({
      format: 'DD/MM/YYYY',
      autoUpdateInput: false,
      locale: {
        cancelLabel: 'Clear'
      },
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-arrow-up",
        down: "fa fa-arrow-down",
        previous: "glyphicon glyphicon-menu-left",
        next: "glyphicon glyphicon-menu-right"
      }
    });
    $('input[name="datefilter"]').on('apply.daterangepicker', function (ev, picker) {
      $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    });
    $('input[name="datefilter"]').on('cancel.daterangepicker', function (ev, picker) {
      $(this).val('');
    });
  });
  $(document).ready(function () {

    $('input[name=desco_acount_type]').change(function () {
      if ($(this).val() == 'desco_prepaid') {
        $(".desco_postpaid_field").removeClass("show_field");
        $(".desco_prepaid_field").addClass("show_field");
      } else {
        $(".desco_postpaid_field").addClass("show_field");
        $(".desco_prepaid_field").removeClass("show_field");
      }
    });
    $("#tol-bill-amnt").click(function () {
      $('.custom-checkbox-fields input:checkbox').not(this).prop('checked', this.checked);
    });

    $("input.autocomplete").autocomplete({
      source: [states]
    });
    // OTP Last Input keyup function
    $(".keyup_fuction_last").on("keyup", function () {
      var len = $(this).val().length;
      if (len >= 2) {
        // $('.btn_full_width_disable').addClass('btn_full_width_active');
        $(".btn_full_width_disable").prop("disabled", false);
        $(".btn_full_width_disable").removeClass('disabled');
      } else {
        $(".btn_full_width_disable").prop("disabled", true);
      }
    });
    // $(".select2-container").on('click', function () {
    //   $('.select-lebel').addClass('select-lebel-up');
    // });
    $(".autocomplete").on('focus', function () {
      $('.select-lebel').addClass('select-lebel-up');
    });
    $(".autocomplete").on('blur', function () {
      var len = $(this).val();
      if (len == '') {
        $('.select-lebel').removeClass('select-lebel-up');
      }
    });
    $('.minus').click(function () {
      var $input = $(this).parent().find('input');
      var count = parseInt($input.val()) - 1;
      count = count < 1 ? 1 : count;
      $input.val(count);
      $input.change();
      return false;
    });
    $('.plus').click(function () {
      var $input = $(this).parent().find('input');
      $input.val(parseInt($input.val()) + 1);
      $input.change();
      return false;
    });
  });
  var states = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
    'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
    'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
    'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
    'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
    'New Jersey', 'New Mexico', 'New York', 'North Carolina',
    'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
    'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
    'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
    'West Virginia', 'Wisconsin', 'Wyoming'
  ];
  var swiper = new Swiper('#big-offer-each', {
    slidesPerView: 'auto',
    freeMode: true,
    spaceBetween: 0,
    speed: 1500,
    loop: true,
    autoplay: false,
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: false,
    // },
    navigation: {
      nextEl: '.big-carousel-next',
      prevEl: '.big-carousel-prev',
    },
    breakpoints: {
      640: {
        freeMode: false,
        slidesPerView: 1,
        spaceBetween: 0,
      },
      320: {
        freeMode: false,
        slidesPerView: 1,
        spaceBetween: 0,
      }
    }
  });
  var swiper = new Swiper('#small-offer-carousel', {
    slidesPerView: 2,
    spaceBetween: 20,
    speed: 2000,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.small-offer-carousel-next',
      prevEl: '.small-offer-carousel-prev',
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
      }
    }
  });
  var mySwiper = new Swiper('#movie-list-carousel', {
    slidesPerView: 6,
    // spaceBetween: 2,
    freeMode: true,
    speed: 1500,
    loop: true,
    autoplay: false,
    // simulateTouch: false,
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: false,
    // },
    observer: true,
    observeParents: true,
    navigation: {
      nextEl: '.movie-list-carousel',
      prevEl: '.movie-list-carousel',
    },
    // breakpoints: {
    //   640: {
    //     freeMode: false,
    //     slidesPerView: 1,
    //     spaceBetween: 0,
    //   },
    //   320: {
    //     freeMode: false,
    //     slidesPerView: 1,
    //     spaceBetween: 0,
    //   }
    // }
  });
  $('.movie-list-carousel li').on('click', function () {
    $('.movie-list-carousel li').not(this).removeClass('selected');
    $(this).addClass('selected');
  });
  // $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
  //   var paneTarget = $(e.target).attr('href');
  //   var $thePane = $('.tab-pane' + paneTarget);
  //   var paneIndex = $thePane.index();
  //   if ($thePane.find('.swiper-container').length > 0 && 0 === $thePane.find('.swiper-slide-active').length) {
  //     mySwiper[paneIndex].update();
  //   }
  // });
  // jQuery for page scrolling feature - requires jQuery Easing plugin
  $('.page-scroll a').bind('click', function (event) {
    var $anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: ($($anchor.attr('href')).offset().top - 50)
    }, 1250, 'easeInOutExpo');
    event.preventDefault();
  });

  // Highlight the top nav as scrolling occurs
  $('body').scrollspy({
    target: '.navbar-fixed-top',
    offset: 51
  });


  // Closes the Responsive Menu on Menu Item Click
  $('.navbar-collapse ul li a').on('click', function () {
    $('.navbar-toggle:visible').click();
  });

  // Offset for Main Navigation
  $('#mainNav').affix({
    offset: {
      top: 100
    }
  })

  // Floating label headings for the contact form
  $(function () {
    $("body").on("input propertychange", ".floating-label-form-group", function (e) {
      $(this).toggleClass("floating-label-form-group-with-value", !!$(e.target).val());
    }).on("focus", ".floating-label-form-group", function () {
      $(this).addClass("floating-label-form-group-with-focus");
    }).on("blur", ".floating-label-form-group", function () {
      $(this).removeClass("floating-label-form-group-with-focus");
    });
  });

})(jQuery); // End of use strict
function handleClickFormly(){
  console.log('something');
}
